import { useVisJs } from '../../hooks/useVisJs'
import {
  EdgeDataKeys,
  EdgeDataLabel,
  EdgeInfoToOmit,
  FilterTypeString,
  NodeDataKeys,
  NodeDataLabel,
  NodeInfoToOmit
} from '../../types'
import NetworkGraph from 'react-graph-vis-ts'

export const VisJs = () => {
  const {
    graphProps,
    filters,
    filterOptions,
    nodePreview,
    edgePreview,
    loading
  } = useVisJs()

  return (
    <div className='container mx-auto px-2 py-8 flex flex-col h-full'>
      <h2 className='text-3xl font-bold'>Network Graph</h2>
      <div className='grid gap-4 my-8'>
        <div className='grid grid-cols-3 gap-4'>
          <div className='flex flex-col'>
            <p>Select Period</p>
            <div className='flex '>
              <select
                className='w-full'
                aria-label='Period select'
                value={filters.period}
                disabled={!loading.isDone}
                onChange={(event) => {
                  filters.applyFilter({
                    filterType: FilterTypeString.period,
                    value: parseInt(event.target.value)
                  })
                }}
              >
                <option key={`period-0`} value={0} hidden>
                  Select
                </option>
                {filterOptions.periodOptions.map((option) => (
                  <option key={`period-${option.value}`} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
              <button
                className='text-white font-bold bg-red-600 hover:bg-red-900 w-10 h-full'
                disabled={!loading.isDone}
                onClick={() => {
                  filters.applyFilter({
                    filterType: FilterTypeString.period,
                    value: 0
                  })
                }}
              >
                X
              </button>
            </div>
          </div>
        </div>
        <div className='grid grid-cols-3 gap-4'>
          <div className='flex flex-col'>
            <p>Highlight by Product</p>
            <div className='flex '>
              <select
                className='w-full'
                aria-label='Highlight by Product'
                value={filters.product}
                disabled={!loading.isDone}
                onChange={(event) => {
                  filters.applyFilter({
                    filterType: FilterTypeString.product,
                    value: event.target.value
                  })
                }}
              >
                <option key={`product-0`} value={''} hidden>
                  Select
                </option>
                {filterOptions.productOptions.map((option) => (
                  <option key={`product-${option.value}`} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
              <button
                className='text-white font-bold bg-red-600 hover:bg-red-900 w-10 h-full'
                disabled={!loading.isDone}
                onClick={() => {
                  filters.applyFilter({
                    filterType: FilterTypeString.product,
                    value: ''
                  })
                }}
              >
                X
              </button>
            </div>
          </div>
          <div className='flex flex-col'>
            <p>Heat map</p>
            <div className='flex '>
              <select
                className='w-full'
                aria-label='Heat map'
                value={filters.heatViewPerProperty}
                disabled={!loading.isDone}
                onChange={(event) => {
                  filters.applyFilter({
                    filterType: FilterTypeString.heatViewPerProperty,
                    value: event.target.value
                  })
                }}
              >
                <option key={`period-0`} value={''} hidden>
                  Select
                </option>
                {filterOptions.heatMapOptions.map((option) => (
                  <option key={`heatMap-${option.value}`} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
              <button
                className='text-white font-bold bg-red-600 hover:bg-red-900 w-10 h-full'
                disabled={!loading.isDone}
                onClick={() => {
                  filters.applyFilter({
                    filterType: FilterTypeString.heatViewPerProperty,
                    value: ''
                  })
                }}
              >
                X
              </button>
            </div>
          </div>
          <div className='flex flex-col'>
            <p>Highligh Corridors</p>
            <div className='flex '>
              <select
                className='w-full'
                aria-label='Highligh Corridors'
                value={filters.corridors}
                disabled={!loading.isDone}
                onChange={(event) => {
                  filters.applyFilter({
                    filterType: FilterTypeString.corridors,
                    value: event.target.value
                  })
                }}
              >
                <option key={`corridor-all`} value={[]} hidden>
                  {!!filters.corridors.length && filters.corridors.join(', ')}
                  {!filters.corridors.length && 'Select'}
                </option>
                {!filterOptions.corridorsOptions.length && (
                  <option key={`corridor-empty`} disabled={true}>
                    No items to select
                  </option>
                )}
                {filterOptions.corridorsOptions.map((option) => (
                  <option
                    key={`corridor-${option.value}`}
                    value={option.value}
                    className={
                      filters.corridors.includes(option.value)
                        ? 'bg-primary text-white'
                        : 'bg-transparent text-black'
                    }
                  >
                    {option.label}
                  </option>
                ))}
              </select>
              <button
                className='text-white font-bold bg-red-600 hover:bg-red-900 w-10 h-full'
                disabled={!loading.isDone}
                onClick={() => {
                  filters.applyFilter({
                    filterType: FilterTypeString.corridors,
                    value: ''
                  })
                }}
              >
                X
              </button>
            </div>
            <div className='flex gap-2 mt-1'>
              {filters.corridors.map((corridor) => (
                <button
                  className='rounded-lg text-white text-xs font-bold bg-blue-400 hover:bg-blue-600 py-1 px-2'
                  key={`selected-${corridor}`}
                  onClick={() => {
                    filters.deleteSingleCorridor(corridor)
                  }}
                >
                  {corridor}
                </button>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div
        className='h-full border border-gray-400 relative'
        style={{ backgroundColor: '#EEEEEE' }}
      >
        {!loading.isDone && (
          <div style={{ height: '16px' }}>
            <div style={{ height: '100%', width: `${loading.percentage}%` }} />
          </div>
        )}
        <div style={{ height: '100%' }}>
          <NetworkGraph
            ref={graphProps.visJsRef}
            identifier='react-visjs-graph'
            graph={graphProps.dataSet}
            events={graphProps.events}
            options={graphProps.options}
            getNetwork={graphProps.onGetNetwork}
            getNodes={graphProps.onGetNodes}
            getEdges={graphProps.onGetEdges}
          />
        </div>
        {(!!nodePreview || !!edgePreview) && (
          <div
            className='absolute bg-white rounded-md flex content-between border border-gray-400 p-3 bottom-0 left-0'
            style={{ maxWidth: '750px' }}
          >
            {!!nodePreview && (
              <div style={{ width: '400px', maxWidth: '400px' }}>
                <div className='text-lg'>
                  Asset: <strong>{nodePreview.label}</strong>
                </div>
                <div className='bg-gray-300 w-full h-[1px] my-2' />
                <div
                  className='flex flex-col flex-wrap gap-2'
                  style={{
                    maxHeight: '150px',
                    height: '150px',
                    width: '100%'
                  }}
                >
                  {Object.values(NodeDataKeys).map(
                    (key) =>
                      !NodeInfoToOmit.includes(key) && (
                        <div key={`asset-${key}`} style={{ fontSize: '10pt' }}>
                          {NodeDataLabel[key]}:{' '}
                          <strong>
                            {typeof nodePreview.data[key] !== 'number'
                              ? nodePreview.data[key]
                              : (nodePreview.data[key]).toFixed(0)}
                          </strong>
                        </div>
                      )
                  )}
                </div>
              </div>
            )}

            {!!nodePreview && !!edgePreview && (
              <div
                className='bg-gray-300 mx-2'
                style={{
                  minHeight: '100%',
                  minWidth: '3px'
                }}
              />
            )}

            {!!edgePreview && (
              <div style={{ width: '300px', maxWidth: '300px' }}>
                <div className='text-lg'>
                  Product: <strong>{edgePreview.title}</strong>
                </div>
                <div className='bg-gray-300 w-full h-[1px] my-2' />
                <div
                  className='flex flex-col flex-wrap gap-2'
                  style={{
                    maxHeight: '150px',
                    height: '150px',
                    width: '100%'
                  }}
                >
                  {Object.values(EdgeDataKeys).map(
                    (key) =>
                      !EdgeInfoToOmit.includes(key) && (
                        <div
                          key={`product-${key}`}
                          style={{ fontSize: '10pt' }}
                        >
                          {EdgeDataLabel[key]}:{' '}
                          <strong>
                            {typeof edgePreview.data[key] !== 'number'
                              ? edgePreview.data[key]
                              : (edgePreview.data[key]).toFixed(2)}
                          </strong>
                        </div>
                      )
                  )}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  )
}
